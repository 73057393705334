@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@font-face {
font-family: NeutralFace;
src: url(NeutralFace.otf);
}

@font-face {
font-family: Trap;
src: url(Trap-SemiBold.otf);
}

@font-face {
font-family: GeneralSans;
src: url(GeneralSans-Medium.otf);
}

@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@font-face {
font-family: NeutralFace;
src: url(/static/media/NeutralFace.13c1a27c.otf);
}

@font-face {
font-family: Trap;
src: url(/static/media/Trap-SemiBold.5144102e.otf);
}

@font-face {
font-family: GeneralSans;
src: url(/static/media/GeneralSans-Medium.95afa044.otf);
}

